.card {
    box-shadow: $box-shadow-sm;
    // box-shadow: none;
    margin-bottom: 0;
    // border-radius: 0;
    position: relative;

    &.card-aggregation {
        a {
            display: block;
            &:hover {
                background-color: $gray-200;
            }
        }

        .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        .col-6, .col-lg-2 {
            padding-left: 0;
            padding-right: 0;
        }

    }

    .card-title {
        font-weight: bold;
    }

}

.bankruptcy-upload-doc-card {
    .wrapper {
        border-top-right-radius: 0 !important;
    }

    .btn {
        border-radius: 0 !important;
        border-bottom: none;

        &.btn-info {
            &:hover {
                border-right-color: $info !important;
            }
        }

        &.btn-rounded {
            border-top-left-radius: $border-radius !important;
        }

        &.btn-rounded-right {
            border-top-right-radius: $border-radius !important;
        }

        &:hover {
            transform: translateY(0px);
            border-right-width: 5px;
        }
   
    }

}

.main-card {
    border-width: 4px !important;

    .image-container {
        max-height: 135px !important;
    }
}

@media (max-width: 600px) {
    .row-sm-auto { 
        display: block;

        .col-auto {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    .main-card {
        width: 100% !important;
        max-width: 100% !important;
    }
}