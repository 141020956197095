.nav-pills {

     .nav-link {
        border-radius: $border-radius;
        background: $white;
        border: solid 2px $orange;
        // color: $white;
        color: $orange;
        box-shadow: none;
        padding: .2rem;
        transition: all .2s;

        &:hover {
            background: lighten($orange, 20%);
            color: $white;
            cursor: pointer;
        }

        &.active {
            background: $warning !important;
            color: $white !important;

        }
    }
}


.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
    max-height: 500px !important;
}

.layout-sidebar .sidenav.navbar-vertical.navbar .sidenav-header {
    height: 74px !important;
}

#desktop-navbar-top {

    .navbar-portal-link {
        font-size: 14px;
        border-radius: $border-radius;
        padding: 6px 16px;
        background: $white;
        border: dashed 1px $gray-600;
        font-weight: bold;
        transition: all .2s;

        &:hover {
            background: $gray-700;
            color: $white !important;
        }
        // color: white;
    }

    ul {
        display: inline-block;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;

        li {
            list-style: none;
            display: inline-block;
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    ul.ul-standard {
        position: relative;
        top: -10px;
        
        li {
            margin-left: 20px;
        }

    }

    ul.navbar-icons {
     

        li {
            list-style: none;
            display: inline-block;

            &:nth-child(2) {
                .circle-wrapper {
                    background: $orange !important;

                    .circle-wrapper-marker {
                        background: darken($orange, 20%);
                    }
                }
            }
            &:nth-child(3) {
                .circle-wrapper {
                    background: $purple !important;

                    .circle-wrapper-marker {
                        background: darken($purple, 20%);
                    }
                }
            }


            .circle-wrapper {
                width: 32px;
                height: 32px;
                background: $teal;
                color: $white;
                text-align: center;
                display: inline-block;
                border-radius: 100%;
                padding-top: 5px;
                font-size: 16px;
                position: relative;
                margin-left: 20px;
                border: solid 1px #ddd;

                i {
                    position: absolute;
                    top: calc(50% - 8px);
                    left: calc(50% - 8px);
                    // z-index: 2;
                }

                .circle-wrapper-marker {
                    position: absolute;
                    top: -12px;
                    right: -12px;
                    color: black;
                    background: darken($teal, 20%);
                    border-radius: 100%;
                    width: 26px;
                    text-align: center;
                    border: dotted 1px white;
                    color: $white;
                    font-size: 12px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }

            }
        }
    }

} 

@include media-breakpoint-down(sm) {
    .dash-header-open {

        .btn {
            display: inline-block !important;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-top: $spacer * 1.5;
        }

    }

    .g-sidenav-pinned .sidenav {
        max-width: 300px !important;
    }
    
    .navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand {
        // padding-top: 20px !important;
        // padding-bottom: 20px !important;

        img {
            width: 75%;
            margin-left: -8px;
        }
    }
}